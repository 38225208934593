export const gettersTypes = {
  id: '[company] id',
  name: '[company] name',
  isCurrentCompany: '[company] isCurrentCompany',
  isCreator: '[company] isCreator',
  isOrganizer: '[company] isOrganizer',
  isManager: '[company] isManager',
  isLeader: '[company] isLeader',
  isSupervisor: '[company] isSupervisor',
  isEmployee: '[company] isEmployee',
  isPhoneAdaptationEnabled: '[company] isPhoneAdaptationEnabled',
  isAuthEmailsEnabled: '[company] isAuthEmailsEnabled',
  isSkipTargetSelfEvalEnabled: '[company] isSkipTargetSelfEvalEnabled',
  targetAllowEditAfterApprove: '[company] targetAllowEditAfterApprove',
  targetAssigneeCommentRequired: '[company] targetAssigneeCommentRequired',
  targetEsteemCondensed: '[company] targetEsteemCondensed',
  allowTargetCompletion: '[company] allowTargetCompletion',
  canTeamsManage: '[company] canTeamsManage',
  organizers: '[company] organizers',
  currentEmployees: '[company] current employees',
  subordinateEmployees: '[company] subordinate employees',
  dismissedEmployees: '[company] dismissed employees',
  invitedEmployees: '[company] invited employees',
  pendingEmployees: '[company] pending employees',
  settings: '[company] settings',
  licenseCount: '[company] license count',
  isHideSelfTesting: '[company] hide self testing',
  useNew360Report: '[company] use new 360 report',
  // modules
  isEvaluationEnabled: '[company] module evaluation is enabled',
  isFeedbackEnabled: '[company] module feedback is enabled',
  isAdaptationEnabled: '[company] module adaptation is enabled',
  isTestsAndSurveysEnabled: '[company] module test and surveys is enabled',
  isPersonalTestsEnabled: '[company] module personal tests is enabled',
  isInterconnectEnabled: '[company] module interconnect is enabled',
  isTargetsEnabled: '[company] module targets is enabled',
  isMeetingsEnabled: '[company] module meetings is enabled',
  isAnyModuleEnabled: '[company] any module is enabled',
  // modules for current company
  isEvaluationEnabledCC: '[company] module evaluation is enabled for current company',
  isFeedbackEnabledCC: '[company] module feedback is enabled for current company',
  isAdaptationEnabledCC: '[company] module adaptation is enabled for current company',
  isTestsAndSurveysEnabledCC: '[company] module test and surveys is enabled for current company',
  isPersonalTestsEnabledCC: '[company] module personal tests is enabled for current company',
  isInterconnectEnabledCC: '[company] module interconnect is enabled for current company',
  isTargetsEnabledCC: '[company] module targets is enabled for current company',
  isMeetingsEnabledCC: '[company] module meetings is enabled for current company',
  isAnyModuleEnabledCC: '[company] any module is enabled for current company',
};

const getters = {
  [gettersTypes.id]: (state) => {
    if (!state.metadata) return 0;
    return state.metadata.company_id;
  },
  [gettersTypes.name]: (state) => {
    if (!state.metadata) return null;
    return state.metadata.name;
  },
  [gettersTypes.isCurrentCompany]: (state, getters, rootState) => {
    if (!rootState.auth.currentUser) return false;
    return getters[gettersTypes.id] === rootState.auth.currentUser.company_id;
  },
  [gettersTypes.isCreator]: (state, getters, rootState) => {
    if (!state.metadata || !rootState.auth.currentUser) return false;
    return state.metadata.user_id === rootState.auth.currentUser.user_id;
  },
  [gettersTypes.isOrganizer]: (state, getters) => {
    if (!state.metadata) return false;
    return getters[gettersTypes.isCreator] || state.metadata.is_organizer;
  },
  [gettersTypes.isManager]: (state) => {
    if (!state.metadata) return false;
    return state.metadata.is_manager;
  },
  [gettersTypes.isLeader]: (state) => {
    if (!state.metadata) return false;
    return state.metadata.is_lead;
  },
  [gettersTypes.isSupervisor]: (state) => {
    if (!state.metadata) return false;
    return state.metadata.is_supervisor;
  },
  [gettersTypes.canTeamsManage]: (state, getters) => {
    if (!state.metadata) return false;
    return (
      getters[gettersTypes.isOrganizer] ||
      getters[gettersTypes.isSupervisor] ||
      getters[gettersTypes.isLeader]
    );
  },
  [gettersTypes.isEmployee]: (state) => {
    if (!state.metadata) return false;
    return state.metadata.is_employee;
  },
  [gettersTypes.isPhoneAdaptationEnabled]: (state) => {
    if (!state.metadata) return false;
    return state.metadata.phone_adaptation;
  },
  [gettersTypes.isAuthEmailsEnabled]: (state) => {
    if (!state.metadata) return false;
    return state.metadata.password_mails;
  },
  [gettersTypes.isSkipTargetSelfEvalEnabled]: (state) => {
    if (!state.metadata) return false;
    return state.metadata.skip_target_self_eval;
  },
  [gettersTypes.targetAllowEditAfterApprove]: (state) => {
    if (!state.metadata) return false;
    return state.metadata.target_allow_edit_after_approve;
  },
  [gettersTypes.targetAssigneeCommentRequired]: (state) => {
    if (!state.metadata) return false;
    return state.metadata.target_assignee_comment_required;
  },
  [gettersTypes.targetEsteemCondensed]: (state) => {
    if (!state.metadata) return false;
    return state.metadata.target_esteem_condensed;
  },
  [gettersTypes.allowTargetCompletion]: (state) => {
    if (!state.metadata) return false;
    return state.metadata.allow_target_completion;
  },
  [gettersTypes.organizers]: (state) => {
    if (!state.employees) return [];
    return state.employees.filter((el) => el.employee_state === 'organizer');
  },
  [gettersTypes.currentEmployees]: (state) => {
    if (!state.employees) return [];
    return state.employees.filter(
      (el) => el.employee_state === 'organizer' || el.employee_state === 'employee'
    );
  },
  [gettersTypes.subordinateEmployees]: (state, getters) => {
    if (!getters[gettersTypes.currentEmployees]) return [];

    return getters[gettersTypes.currentEmployees].filter(
      (el) => el.is_subordinate_for_current_user
    );
  },
  [gettersTypes.invitedEmployees]: (state) => {
    if (!state.employees) return [];
    return state.employees.filter((el) => el.employee_state === 'invited');
  },
  [gettersTypes.pendingEmployees]: (state) => {
    if (!state.employees) return [];
    return state.employees.filter((el) => el.employee_state === 'pending');
  },
  [gettersTypes.dismissedEmployees]: (state) => {
    if (!state.employees) return [];
    return state.employees.filter((el) => el.employee_state === 'dismissed');
  },
  [gettersTypes.settings]: (state, getters) => {
    return getters[gettersTypes.isCurrentCompany] ? state.currentSettings : state.settings;
  },
  [gettersTypes.licenseCount]: (state, getters) => {
    if (!getters[gettersTypes.settings]) return 0;
    return getters[gettersTypes.settings].max_employee_number || 0;
  },
  [gettersTypes.isHideSelfTesting]: (state) => {
    if (!state.metadata) return false;
    return state.metadata.hide_self_testing || false;
  },
  [gettersTypes.useNew360Report]: (state) => {
    if (!state.metadata) return false;
    return state.metadata.use_new_360_report || false;
  },
  //modules
  [gettersTypes.isEvaluationEnabled]: (state, getters) => {
    const settings = getters[gettersTypes.settings];

    if (!settings || !settings.modules) return false;
    return settings.modules.evaluation_enabled || false;
  },
  [gettersTypes.isFeedbackEnabled]: (state, getters) => {
    const settings = getters[gettersTypes.settings];

    if (!settings || !settings.modules) return false;
    return settings.modules.feedback_enabled || false;
  },
  [gettersTypes.isAdaptationEnabled]: (state, getters) => {
    const settings = getters[gettersTypes.settings];

    if (!settings || !settings.modules) return false;
    return settings.modules.adaptation_enabled || false;
  },
  [gettersTypes.isTestsAndSurveysEnabled]: (state, getters) => {
    const settings = getters[gettersTypes.settings];

    if (!settings || !settings.modules) return false;
    return settings.modules.tests_and_surveys_enabled || false;
  },
  [gettersTypes.isPersonalTestsEnabled]: (state, getters) => {
    const settings = getters[gettersTypes.settings];

    if (!settings || !settings.modules) return false;
    return settings.modules.ht_tests_enabled || false;
  },
  [gettersTypes.isInterconnectEnabled]: (state, getters) => {
    const settings = getters[gettersTypes.settings];

    if (!settings || !settings.modules) return false;
    return settings.modules.interconnect_enabled || false;
  },
  [gettersTypes.isTargetsEnabled]: (state, getters) => {
    const settings = getters[gettersTypes.settings];

    if (!settings || !settings.modules) return false;
    return settings.modules.targets_enabled || false;
  },
  [gettersTypes.isMeetingsEnabled]: (state, getters) => {
    const settings = getters[gettersTypes.settings];

    if (!settings || !settings.modules) return false;
    return settings.modules.meetings_enabled || false;
  },
  [gettersTypes.isAnyModuleEnabled]: (state, getters) => {
    return (
      getters[gettersTypes.isEvaluationEnabled] ||
      getters[gettersTypes.isFeedbackEnabled] ||
      getters[gettersTypes.isAdaptationEnabled] ||
      getters[gettersTypes.isTestsAndSurveysEnabled] ||
      getters[gettersTypes.isPersonalTestsEnabled] ||
      getters[gettersTypes.isInterconnectEnabled] ||
      getters[gettersTypes.isTargetsEnabled] ||
      getters[gettersTypes.isMeetingsEnabled]
    );
  },
  //modules for current user company
  [gettersTypes.isEvaluationEnabledCC]: (state) => {
    if (!state.currentSettings || !state.currentSettings.modules) return false;
    return state.currentSettings.modules.evaluation_enabled || false;
  },
  [gettersTypes.isFeedbackEnabledCC]: (state) => {
    if (!state.currentSettings || !state.currentSettings.modules) return false;
    return state.currentSettings.modules.feedback_enabled || false;
  },
  [gettersTypes.isAdaptationEnabledCC]: (state) => {
    if (!state.currentSettings || !state.currentSettings.modules) return false;
    return state.currentSettings.modules.adaptation_enabled || false;
  },
  [gettersTypes.isTestsAndSurveysEnabledCC]: (state) => {
    if (!state.currentSettings || !state.currentSettings.modules) return false;
    return state.currentSettings.modules.tests_and_surveys_enabled || false;
  },
  [gettersTypes.isPersonalTestsEnabledCC]: (state) => {
    if (!state.currentSettings || !state.currentSettings.modules) return false;
    return state.currentSettings.modules.ht_tests_enabled || false;
  },
  [gettersTypes.isInterconnectEnabledCC]: (state) => {
    if (!state.currentSettings || !state.currentSettings.modules) return false;
    return state.currentSettings.modules.interconnect_enabled || false;
  },
  [gettersTypes.isTargetsEnabledCC]: (state) => {
    if (!state.currentSettings || !state.currentSettings.modules) return false;
    return state.currentSettings.modules.targets_enabled || false;
  },
  [gettersTypes.isMeetingsEnabledCC]: (state) => {
    if (!state.currentSettings || !state.currentSettings.modules) return false;
    return state.currentSettings.modules.meetings_enabled || false;
  },
  [gettersTypes.isAnyModuleEnabledCC]: (state, getters) => {
    return (
      getters[gettersTypes.isEvaluationEnabledCC] ||
      getters[gettersTypes.isFeedbackEnabledCC] ||
      getters[gettersTypes.isAdaptationEnabledCC] ||
      getters[gettersTypes.isTestsAndSurveysEnabledCC] ||
      getters[gettersTypes.isPersonalTestsEnabledCC] ||
      getters[gettersTypes.isInterconnectEnabledCC] ||
      getters[gettersTypes.isTargetsEnabledCC] ||
      getters[gettersTypes.isMeetingsEnabledCC]
    );
  },
};

export default getters;
