import Vue from 'vue';
export const mutationTypes = {
  setLoadingObject: '[analytic] set loading object',
  storeStatistic: '[analytic] store statistic',
  storeEmotion: '[analytic] store emotion',
  storeEnps: '[analytic] store enps',
  storeParticipation: '[analytic] store participation',
  storeParticipationFactors: '[analytic] store participation factors',
  storeFilterValue: '[analytic] store filter value',
  storeFilterReset: '[analytic] store filter reset',
  storeFilterInit: '[analytic] store filter init',
  storeBurnout: '[analytic] store burnout',
  storeBurnoutByEmployee: '[analytic] store burnout by employee',
  storeTeams: '[analytic] store teams',
};

const camelize = (s) => s.replace(/-./g, (x) => x[1].toUpperCase());

const mutations = {
  /**
   * @param {string[]} loadderObj
   * @param {boolean} data
   * */
  [mutationTypes.setLoadingObject](state, params = {loaderObj: [], data: false}) {
    const {loaderObj, data} = params;

    if (loaderObj && loaderObj.length) {
      loaderObj.forEach((key) => {
        if (state.loadingObjCount[key] === undefined) Vue.set(state.loadingObjCount, key, 0);

        data
          ? Vue.set(state.loadingObjCount, key, state.loadingObjCount[key] + 1)
          : Vue.set(state.loadingObjCount, key, state.loadingObjCount[key] - 1);

        if (state.loadingObjCount[key] < 0) Vue.set(state.loadingObjCount, key, 0);

        if ((!data && state.loadingObjCount[key] === 0) || data)
          Vue.set(state.loadingObjCount, key, data);
      });
    }
  },

  [mutationTypes.storeStatistic](state, {reportType, payload}) {
    Vue.set(state.statistic, reportType, payload);
  },

  [mutationTypes.storeEmotion](state, {reportType, payload}) {
    Vue.set(state.emotion, reportType, payload);
  },

  [mutationTypes.storeEnps](state, payload) {
    state.enps = payload;
  },

  [mutationTypes.storeParticipation](state, {reportType, payload}) {
    Vue.set(state.participation, camelize(reportType), payload);
  },

  [mutationTypes.storeParticipationFactors](state, {reportType, payload}) {
    Vue.set(state.participationFactors, camelize(reportType), payload);
  },

  [mutationTypes.storeFilterValue](state, {key, value}) {
    Vue.set(state.filters, key, value);
  },

  [mutationTypes.storeFilterReset](state) {
    state.filters = Object.fromEntries(
      Object.entries(state.filters).map(([key]) => [key, undefined])
    );
  },

  [mutationTypes.storeFilterInit](state) {
    state.filterInited = true;
  },

  [mutationTypes.storeBurnout](state, {reportType, payload}) {
    Vue.set(state.burnout, camelize(reportType), payload);
  },

  [mutationTypes.storeBurnoutByEmployee](state, {reportType, payload}) {
    Vue.set(state.burnoutByEmployee, camelize(reportType), payload);
  },

  [mutationTypes.storeTeams](state, payload) {
    state.teams = payload;
  },
};

export default mutations;
