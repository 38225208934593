export const gettersTypes = {
  checkLoading: '[analytic] checkLoading',
  filterTeamIds: '[analytic] filterTeamIds',
  filterCycle: '[analytic] filterCycle',
  mapedTeamIdesForParent: '[analytic] mapedTeamIdesForParent',
};

const getters = {
  [gettersTypes.checkLoading]: (state) => {
    return (loaderName) => {
      if (Array.isArray(loaderName)) {
        return (
          Object.keys(state.loadingObjCount).filter((key) => {
            return loaderName.includes(key) && state.loadingObjCount[key] === true;
          }).length > 0
        );
      }
      return state.loadingObjCount[loaderName] || false;
    };
  },

  [gettersTypes.filterTeamIds]: (state) => {
    if (!state.filters.team) return undefined;

    return state.filters.team.map((item) => item.id).join(',');
  },

  [gettersTypes.filterCycle]: (state) => {
    return state.filters.cycle;
  },
};

export default getters;
